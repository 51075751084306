import Dividend from "../models/Dividend";

const dividends = [
	new Dividend(new Date("2023-09-15"), 0.1815, "$", 50, "LZB", 0.15, 0),
	new Dividend(new Date("2023-06-15"), 0.1815, "$", 50, "LZB", 0.15, 0),
	new Dividend(new Date("2023-08-17"), 0.99, "$", 26, "ATVI", 0.15, 0),
	new Dividend(new Date("2023-12-18"), 0.2, "$", 50, "LZB", 0.15, 0),
	new Dividend(new Date("2024-02-01"), 0.5772, "£", 180, "BATS", 0, 0),
	new Dividend(new Date("2024-05-02"), 0.588795, "£", 180, "BATS", 0, 0),
	new Dividend(new Date("2024-03-15"), 0.2, "$", 50, "LZB", 0.15, 0),
	new Dividend(new Date("2024-05-16"), 0.25, "$", 140, "AAPL", 0.15, 0),
	new Dividend(new Date("2024-06-14"), 0.2, "$", 50, "LZB", 0.15, 0),
	new Dividend(new Date("2024-06-17"), 0.2, "$", 125, "GOOGL", 0.15, 0),
	new Dividend(new Date("2024-08-02"), 0.5888, "£", 180, "BATS", 0, 0),
	new Dividend(new Date("2024-08-15"), 0.25, "$", 140, "AAPL", 0.15, 0),

	new Dividend(new Date("2024-09-16"), 0.2, "$", 50, "LZB", 0.15, 0),
	new Dividend(new Date("2024-09-16"), 0.2, "$", 125, "GOOGL", 0.15, 0),
	new Dividend(new Date("2024-11-01"), 0.5888, "£", 180, "BATS", 0, 0),
	new Dividend(new Date("2024-11-14"), 0.25, "$", 140, "AAPL", 0.15, 0),
	new Dividend(new Date("2024-12-16"), 0.22, "$", 50, "LZB", 0.15, 0),
	new Dividend(new Date("2024-12-16"), 0.2, "$", 125, "GOOGL", 0.15, 0),
	new Dividend(new Date("2025-02-03"), 0.5888, "£", 180, "BATS", 0, 0),
];

dividends.sort((a, b) => b.date.getTime() - a.date.getTime());

export default dividends;
