import React, { useState, type MouseEvent } from "react";
import portfolio from "./data/portfolio";
import transactions from "./data/transactions";
import dividends from "./data/dividends";

function Holdings(): React.JSX.Element {
	const lastUpdate = "2025-02-07";
	const MIN_SIZE = 5;

	const [buttonHoldings, setButtonHoldings] = useState("Show More");
	const [showMoreHoldings, setShowMoreHoldings] = useState(false);
	const [buttonTxs, setButtonTxs] = useState("Show More");
	const [showMoreTxs, setShowMoreTxs] = useState(false);
	const [buttonDivs, setButtonDivs] = useState("Show More");
	const [showMoreDivs, setShowMoreDivs] = useState(false);

	function handleButtonClick(e: MouseEvent<HTMLButtonElement>): void {
		e.preventDefault();
		console.log(e.currentTarget.name);
		switch (e.currentTarget.name) {
			case "holdings":
				if (showMoreHoldings) {
					setButtonHoldings("Show More");
					setShowMoreHoldings(false);
				} else {
					setButtonHoldings("Show Less");
					setShowMoreHoldings(true);
				}
				break;
			case "transactions":
				if (showMoreTxs) {
					setButtonTxs("Show More");
					setShowMoreTxs(false);
				} else {
					setButtonTxs("Show Less");
					setShowMoreTxs(true);
				}
				break;
			case "dividends":
				if (showMoreDivs) {
					setButtonDivs("Show More");
					setShowMoreDivs(false);
				} else {
					setButtonDivs("Show Less");
					setShowMoreDivs(true);
				}
				break;
			default:
				break;
		}
	}

	const baseYahooURL = "https://finance.yahoo.com/quote/";

	return (
		<div className="inner-div">
			<div>
				<div>
					<h2 id="holdings-title" className="holdings-title">
						Holdings
					</h2>
				</div>
				<div className="holdings-section">
					<table className="holdings-table">
						<tbody id="portfolio-results">
							<tr>
								<th>Ticker</th>
								<th className="ticker-data">Shares Quantity</th>
								<th className="price-data">Cost Base *</th>
								<th className="price-data">Realized Revenues *</th>
								<th className="price-data">Total Commissions</th>
								<th className="price-data">Net Dividends **</th>
							</tr>
							{portfolio.map((pos) => {
								if (showMoreHoldings || pos.calcTotalShares() !== 0)
									return (
										<tr>
											<td className="ticker-data">
												<a
													href={baseYahooURL + pos.ticker}
													target="_blank"
													rel="noreferrer">
													{pos.ticker}
												</a>
											</td>
											<td>{pos.calcTotalShares()}</td>
											<td>
												{pos.calcTotalPurchaseCost().toFixed(2)}{" "}
												{pos.currency}
											</td>
											<td>
												{pos.calcTotalSellingRevenue().toFixed(2)}{" "}
												{pos.currency}
											</td>
											<td>
												{pos.calcTotalCommission().toFixed(2)}{" "}
												{pos.currency}
											</td>
											<td>
												{pos.calcNetDividends().toFixed(2)} {pos.currency}
											</td>
										</tr>
									);
								else return null;
							})}
						</tbody>
					</table>
					<br />
				</div>
				<div className="holdings-section">
					<button id="toggle-holdings-button" name="holdings" onClick={handleButtonClick}>
						{buttonHoldings}
					</button>
				</div>
			</div>
			<div>
				<div className="holdings-notes">
					<br />
					* Does not include commissions and corporate income tax or loss tax reliefs.
					<br />
					** Does not include corporate income tax, but includes withholding taxes and
					commissions.
					<br />
					Tax effects on realized gains or losses and dividends are not included, except
					withholdings taxes on dividends.
				</div>
				<br />
				<div className="holdings-update">
					<p>
						Data updated on <strong>{lastUpdate}</strong>
					</p>
				</div>
			</div>
			<hr />
			<div>
				<div>
					<h2 className="holdings-title">Operations History</h2>
				</div>
				<div className="holdings-section">
					<table className="holdings-table">
						<tbody id="transaction-results">
							<tr>
								<th className="holding-data">Date</th>
								<th className="holding-data">Ticker</th>
								<th className="holding-data">Quantity</th>
								<th className="holding-data">Avg. Price</th>
								<th className="holding-data">Operation</th>
								<th className="holding-data">Commission</th>
							</tr>
							{transactions.map((tx, i) => {
								if (i <= MIN_SIZE || showMoreTxs)
									return (
										<tr>
											<td className="holding-data">{tx.getDateString()}</td>
											<td className="holding-data">
												<a
													href={baseYahooURL + tx.ticker}
													target="_blank"
													rel="noreferrer">
													{tx.ticker}
												</a>
											</td>
											<td className="holding-data">{tx.quantity}</td>
											<td className="holding-data">
												{tx.price.toFixed(2)} {tx.currency}
											</td>
											<td className="holding-data">{tx.type}</td>
											<td className="holding-data">
												{(tx.commission * tx.fxRate).toFixed(2)}{" "}
												{tx.currency}
											</td>
										</tr>
									);
								else return null;
							})}
						</tbody>
					</table>
				</div>
				<div className="holdings-section">
					<button
						id="toggle-holdings-button"
						name="transactions"
						onClick={handleButtonClick}>
						{buttonTxs}
					</button>
				</div>
			</div>
			<hr />
			<div>
				<div>
					<h2 className="holdings-title">Dividends History</h2>
				</div>
				<div className="holdings-section">
					<table className="holdings-table">
						<tbody id="dividend-results">
							<tr>
								<th className="holding-data">Date</th>
								<th className="holding-data">Ticker</th>
								<th className="holding-data">Quantity</th>
								<th className="holding-data">Dividend per Share</th>
								<th className="holding-data">Withholding Tax</th>
								<th className="holding-data"> Net Amount</th>
							</tr>
							{dividends.map((div, i) => {
								if (i <= MIN_SIZE || showMoreDivs)
									return (
										<tr>
											<td className="holding-data">{div.getDateString()}</td>
											<td className="holding-data">
												<a
													href={baseYahooURL + div.ticker}
													target="_blank"
													rel="noreferrer">
													{div.ticker}
												</a>
											</td>
											<td className="holding-data">{div.quantity}</td>
											<td className="holding-data">
												{div.dps.toFixed(4)} {div.currency}
											</td>
											<td className="holding-data">
												{div.getTotalWithHoldingTax().toFixed(2)}{" "}
												{div.currency}
											</td>
											<td className="holding-data">
												{div.getNetAmount().toFixed(2)} {div.currency}
											</td>
										</tr>
									);
								else return null;
							})}
						</tbody>
					</table>
					<br />
				</div>
				<div className="holdings-section">
					<button
						id="toggle-holdings-button"
						name="dividends"
						onClick={handleButtonClick}>
						{buttonDivs}
					</button>
				</div>
				<br />
			</div>
		</div>
	);
}

export default Holdings;
